import React from 'react';
import * as Sentry from '@sentry/node';
import { createGlobalStyle } from 'styled-components';
import Head from 'next/head';
import App from 'next/app';

import { AdminBar } from '@tager/web-panel';
import { isomorphicLog, isServer, useProgressBar } from '@tager/web-core';
import { GalleryProvider, ModalProvider } from '@tager/web-components';
import { useAnalytics } from '@tager/web-analytics';

import '@/assets/css/index.css';
import withRedux from '@/hocs/withRedux';
import { CustomApp_Component } from '@/typings/hocs';
import useJivoSite from '@/hooks/useJivoSite';
import useSettingItem from '@/hooks/useSettingItem';
import { changeShadeColor } from '@/utils/common';
import { StringFieldType } from '@/typings/common';
import NavigationMenuProvider from '@/providers/NavigationMenuProvider';

Sentry.init({
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.NEXT_PUBLIC_ENV !== 'local',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    process.env.NEXT_PUBLIC_ENV,
  ].join('_'),
});

/**
 * Custom App documentation
 * https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp: CustomApp_Component = (props) => {
  useProgressBar({ showSpinner: false });
  useAnalytics({
    useBackend: false,
  });
  useJivoSite();

  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // @ts-ignore
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };
  const mainColor = useSettingItem('PRIMARY_COLOR');

  return (
    <>
      <DynamicGlobalStyles mainColor={mainColor} />
      <Head>
        <meta name="viewport" content="width=1260,user-scalable=yes" />
      </Head>
      <GalleryProvider>
        <ModalProvider>
          <NavigationMenuProvider>
            <AdminBar />
            <Component {...modifiedPageProps} />
          </NavigationMenuProvider>
        </ModalProvider>
      </GalleryProvider>
    </>
  );
};

const DynamicGlobalStyles = createGlobalStyle<{ mainColor: StringFieldType }>`
  * {
    --main-color: ${({ mainColor }) => mainColor};
    --main-color-hover: ${({ mainColor }) => changeShadeColor(mainColor, -15)}
  }
`;

/**
 * Only use this method if you have blocking data requirements for
 * every single page in your application. This disables the ability to
 * perform automatic static optimization, causing every page in your app to
 * be server-side rendered.
 *
 * Reference: https://nextjs.org/docs/advanced-features/custom-app
 */
CustomApp.getInitialProps = async (appContext) => {
  let hrstart: [number, number] | null = null;

  if (isServer()) {
    hrstart = process.hrtime();
  }

  /** calls page's `getInitialProps` and fills `appProps.pageProps` */
  const appProps = await App.getInitialProps(appContext);

  if (isServer() && hrstart) {
    const hrend = process.hrtime(hrstart);
    isomorphicLog(
      `Page: ${
        appContext.ctx.asPath
      },  'getInitialProps' execution time (hr): ${hrend[0]}s ${
        hrend[1] / 1000000
      }ms`
    );
  }

  return { ...appProps };
};

export default withRedux(CustomApp);
