import { useEffect } from 'react';

import useSettingItem from '@/hooks/useSettingItem';

function useJivoSite() {
  const widgetId = useSettingItem('SERVICE_JIVOSITE_WIDGET_ID');

  useEffect(() => {
    if (!widgetId) return;

    setTimeout(() => {
      const script = document.createElement('script');
      script.src = `//code.jivosite.com/widget/${widgetId}`;
      script.async = true;
      document.body.appendChild(script);
    }, 2000);
  }, [widgetId]);
}

export default useJivoSite;
